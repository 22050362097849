<template>
    <div class="relative text-white-1">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top pn-about-banner-container w-full">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 mobileL:py-8 lg:py-2" src="../../../assets/images/about-us/pn-about-banner.png" alt="About Us Banner">
                <div class="flex flex-col mx-44 mobileL:mx-0 text-left mobileL:text-center z-10 max-w-xs lg:max-w-md 2xl:max-w-lg p-0 mobileL:p-4 
                ml-6 mobileL:ml-20 lg:ml-32 xl:ml-40 2xl:ml-48 relative mt-8 mobileL:-mt-12">
                    <div class="relative inline-block">
                        <span class="pn-about-bg-highlight absolute top-4 mobileL:top-8 lg:top-10 2xl:top-12 left-0 w-5/6 mobileL:w-full h-1/2 2xl:h-1/2 z-10"></span>
                        <img class="absolute -top-2 -left-2 mobileL:left-8 lg:left-6 2xl:left-6 2xl:top-1 w-3.5 mobileL:w-4 lg:w-5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                        <div class="text-4xl md:text-5xl lg:text-7xl 2xl:text-8xl font-semibold relative z-20">
                            <h1 class="font-kannada font-semibold">About Us</h1>
                        </div>
                        <img class="absolute top-0 lg:-top-4 hidden mobileL:block -right-16 lg:-right-20 z-0 lg:w-28 2xl:w-auto w-20" src="../../../assets/images/home-page/sketch-line.png" alt="">
                    </div>
                    <p class="2xl:mt-6 text-sm mobileL:text-lg lg:text-2xl 2xl:text-4xl -mx-20 ml-0 mobileL:-mx-8 2xl:-mx-16 leading-snug">
                        Passionails is your new favorite hangout place for your mani/pedis with the 
                        galplas or if want to relax and rejuvenate. Every inch of our space has 
                        been carefully planned and created to provide you with the most 
                        comfortable experience.  
                    </p>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/about-us/pn-body-banner.png" alt="Background Image">
            <div class="relative top-0 left-0 w-full h-full flex items-center justify-center flex-col py-2 mobileL:py-4 lg:py-8">
                <div class="text-white-1 font-kannada mobileL:font-monsterrat md:text-xl lg:text-3xl 2xl:text:5xl w-4/5 mobileL:w-3/4 text-center my-4 mobileL:my-8 leading-tight">
                    <p class="text-sm mobileL:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl leading-tight"><span class="leading-none font-semibold mobileL:font-normal text-xl mobileL:text-3xl xl:text-4xl 2xl:text-5xl">Passionails</span>
                        is your new favorite hangout place for your mani/pedis 
                        with the galpals or if want to relax and rejuvenate. <br><br>
                        Every inch of our space has been carefully planned and 
                        created to provide you with the most comfortable experience.
                    </p>
                </div>
            </div>
        </div>
        <div class="pn-bg-our-history px-0 mobileL:px-2 py-6 mobileL:py-12 2xl:px-8 2xl:py-20" id="why-passionails">
            <div class="mx-auto">
                <div class="grid md:grid-cols-2">
                    <div class="">
                        <img class="mx-auto w-60 mobileL:w-auto" src="../../../assets/images/about-us/pn-store.png" alt="Our Store">
                    </div>
                    <div class="flex flex-col justify-center text-center items-center px-12 mobileL:px-0 mobileL:pr-12 py-5">
                        <div class="relative inline-block">
                            <span class="pn-about-bg-highlight absolute top-4 mobileL:top-8 lg:top-10 2xl:top-12 left-0 w-full h-1/2 2xl:h-1/2 z-10"></span>
                            <img class="absolute -top-1 -left-2 lg:left-0 2xl:top-1 w-3.5 mobileL:w-4 lg:w-5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                            <div class="text-4xl md:text-5xl lg:text-7xl 2xl:text-8xl font-semibold relative z-20 text-center items-center flex">
                                <h1 class="font-kannada font-semibold">Our Store</h1>
                            </div>
                        </div>
                        <p class="font-kannada mobileL:font-century 2xl:mt-6 text-sm sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl text-center leading-snug">
                            Minimalist design industrial elements, and cool tones a plenty 
                            our salon is relaxing haven where you can disconnect from the 
                            outside world, and reconnect with yourself. 
                        </p>
                    </div>
                    <div class="flex flex-col justify-center items-center px-12 mobileL:px-0 py-5 order-2 mobileL:order-1">
                        <div class="relative inline-block">
                            <span class="pn-about-bg-highlight absolute top-4 mobileL:top-8 lg:top-10 2xl:top-12 left-0 w-full h-1/2 2xl:h-1/2 z-10"></span>
                            <img class="absolute -top-1 -left-2 lg:left-0 2xl:top-1 w-3.5 mobileL:w-4 lg:w-5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                            <div class="text-4xl md:text-5xl lg:text-7xl 2xl:text-8xl font-semibold relative z-20 text-center items-center flex">
                                <h1 class="font-kannada font-semibold">Our Team</h1>
                            </div>
                        </div>
                        <p class="font-kannada mobileL:font-century 2xl:mt-6 text-sm sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl text-center leading-snug">
                            Our hand picked team of nails artisans have all gone through an in-depth 
                            training, ensuring you always receive a service second to none. 
                            We Pride ourselves on having a nail gang that is made up of only the very best
                        </p>
                    </div>
                    <div class="order-1 mobileL:order-2">
                        <img class="mx-auto w-60 mobileL:w-auto" src="../../../assets/images/about-us/pn-team.png" alt="Our Team">
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/PassionailsFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/passionails/about-us')
    },
}
</script>

<style>
.pn-about-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #dd9d9e 0%, #dd9d9e 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .pn-about-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .pn-about-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .pn-about-banner-container{
        height:255px;
        background: #FFAEBB;
    }
    .space-top {
    margin-top: 60px;
    }
}

.pn-about-banner-title {
    color: #FFF;
    font-style: normal;
}

.pn-bg-our-history {
    background: linear-gradient(45deg, #a271a0, #d4a4b4);
}

.pn-about-bg-highlight {
    position: absolute;
    background: #7D4979;
    left: 0px;
}

</style>